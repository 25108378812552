import React from "react";
import Header from "./layout/Header";
import { Link } from "gatsby";
export default function Hero({ data }) {
  const { title, paragraph, bgImage, cta } = data;
  return (
    <section
      id="hero"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <Header />
      <div className="hero-content">
        <h1>{title}</h1>
        <div className="warper-lp">
          <p>{paragraph}</p>
          <Link to={cta.ctaLink}>{cta.ctaTitle}</Link>
        </div>
      </div>
    </section>
  );
}
