import React from "react";
import Footer from "../components/layout/Footer";
import Hero from "../components/Hero";
export default function PageNotFound() {
  const heroContent = {
    title: "Aucune page trouvé",
    paragraph: "aucune page trouvé avec cette URL",
    bgImage: "img/404.svg",
    cta: {
      ctaTitle: "JE DÉCOUVRE LE FONCTIONNEMENT",
      ctaLink: "/",
    },
  };
  return (
    <>
      <Hero data={heroContent} />
      <Footer />
    </>
  );
}
