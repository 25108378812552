import React from "react"

export default function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <span className="footer__heading">
          <p>© THEOFFICE 2020 - UN SERVICE PROPOSÉ PAR</p>
          <img src="/img/culture.png" alt="" className="footer__heading-logo" />
        </span>
      </div>
    </div>
  )
}
